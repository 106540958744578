import React from 'react';
import cx from 'classnames';
import { graphql } from 'gatsby';
// import { StaticImage } from 'gatsby-plugin-image';

import Grid, { GridItem } from 'components/Grid';
import SEO from '../components/seo';
import Button from '../components/Button';
import Layout from '../components/Layout';

import styles from './donate.module.scss';

const DonatePage = () => {
  return (
    <Layout className={styles.page}>
      <SEO title="Donate" />
      <div className={cx(styles.root, 'content')}>
        <h1 className={cx('title', styles.pageTitle)}>
          GEWS vision is “to be a maiden and leading multilingual, Green, 
          educational and research institute for African girls.
        </h1>
        <Grid gap={4}>
          <Grid col={2} gap={4}>
            <GridItem justify="left">
              <h2 className="mt--none">Support our journey</h2>
              <p>
                {/* <strong>$10 donations</strong> laptops. */}
              </p>

              <p>Anything you can give helps tremendously!</p>
              <h2 className="mt--none">Donate laptops</h2>

              <p>
                Please contact +233 (0) 55 461 8497
              </p>

              <Button
                className="mt--lg"
                href="https://docs.google.com/forms/d/e/viewform"
              >
                Donate laptops
              </Button>
              {/* <StaticImage
                src="../_assets/images/laptop.png"
                alt="Donate laptops"
                className={styles.laptop}
                align="right"
              /> */}
            </GridItem>
            {/* <GridItem align="center">
              <script
                src="https://donorbox.org/widget.js"
                paypalExpress="false"
              />
              <iframe
                title="Donate to GEWS!"
                allowpaymentrequest=""
                className={styles.donorbox}
                frameBorder="0"
                height="900px"
                name="donorbox"
                scrolling="no"
                seamless="seamless"
                src="https://donorbox.org/embed/website-donation-15"
                width="100%"
              />
            </GridItem> */}
          </Grid>
        </Grid>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    Laptop: file(relativePath: { eq: "laptop.png" }) {
      childImageSharp {
        id
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default DonatePage;
